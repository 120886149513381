import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import Header from "/src/components/pages/products/Header";
import KeyFeatures from "/src/components/pages/products/KeyFeatures";
import Benefits from "/src/components/pages/products/Benefits";
import Functions from "/src/components/pages/products/Functions";

import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";

import circuit2 from "/static/assets/animations/circuit/circuit_right01.json";
import RelatedContentSection from "../../components/pages/products/RelatedContentSection";

const ZorpGPL = ({data}) => {

    const {gpl} = data

    return (
        <Layout lang={gpl.lang} seo={gpl.SEO}>
            <Header
                title_white={gpl.product.title_white}
                title_color={gpl.product.title_color}
                brand_color="zorp-gpl-primary"
                subtitle={gpl.product.subtitle}
                description={gpl.product.description}
                url_a={gpl.product.url}
                src={gpl.product.product_icon.url}
            />
            <KeyFeatures
                big_title={gpl.key_features_title}
                cards={gpl.key_features_card}
            />
            <LottieAnimation animationData={circuit2} right="true"/>
            <Benefits
                big_title={gpl.benefits_title}
                benefits={gpl.benefits_card}
                button={gpl.benefits_button}
                isExternal
                button_style="zorp-gpl-primary"
            />
            <Functions
                big_title={gpl.functions_title}
                cards={gpl.function_card}
                button={gpl.function_button}
                isExternal
                button_color="zorp-gpl-primary"
            />
            <RelatedContentSection
                locale={gpl.lang}
                related1_slug={gpl.related_content.related1_slug}
                related2_slug={gpl.related_content.related2_slug}
                related3_slug={gpl.related_content.related3_slug}
                related4_slug={gpl.related_content.related4_slug}
            />
        </Layout>
    )
}

export const query = graphql`
   query GetSingleGPL($locale: String) {
         gpl: strapiZorpGpls(lang: { eq: $locale }) {
          lang
              product {
                title_white
                title_color
                subtitle
                description
                url {
                  link
                  name
                }
                product_icon {
                  url
                }
              }
              key_features_title
              key_features_card {
                avatar {
                  url
                }
                title
                description
                id
              }
              benefits_title
              benefits_card {
                id
                title
              }
              benefits_button {
                id
                link
                name
              }
              functions_title
              function_card {
                id
                title
                description
                avatar {
                  url
                }
                List {
                  id
                  item
                }
              }
              function_button {
                id
                link
                name
              }
              related_content {
                related1_slug
                related2_slug
                related3_slug
              }
              SEO {
                title
                isIndexable
                description
                keywords
                preview {
                  url
                }
              }
      }
   }
 `

export default ZorpGPL